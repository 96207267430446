//
// Components
//

// Import Dependencies
@import 'stepper/multistep';
@import 'landing';
@import 'btn-secondary';


.hide-arrow[type="number"]::-webkit-inner-spin-button,
.hide-arrow[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.text-left {
  text-align: left;
}

.btn-bookmark-item {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  width: 100% !important;
}

.fixed-div {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%; /* Tüm genişliği kaplaması için */
  background-color: #fff; /* Arkaplan rengi */
  padding: 20px 20px 20px 20px;  
  z-index: 50; /* Diğer elementlerin üzerinde kalmasını sağlar */
}

.target-section {
  scroll-margin-top: 200px; /* Üstte ne kadar boşluk kalmasını istiyorsan */
  margin-top: 20px;
}

.fc-event-success {
  background-color: #28a745 !important;
  color: #fff !important;
}

.fc-event-solid-primary {
  background-color: #007bff !important;
}

.fc-event-light {
  color: #fff !important;
}

.fc-event-solid-info {
  background-color: #17a2b8 !important;
}

.fc-event-solid-info-2 {
  background-color: #9eb046 !important;
}
